a {
  text-decoration: none;
  color: rgba(var(--primary-gray), 1);
}

/* Base button styling */
.btn,
button {
  position: relative;
  font-size: var(--fs-sm);
  font-weight: 600;
  display: inline-block;
  height: 30px;
  padding: 0 10px;
  margin: 5px;
  color: rgba(var(--primary-blue), 1);
  text-align: center;
  line-height: 30px;
  text-decoration: none;
  white-space: nowrap;
  background-color: rgba(var(--primary-white), 1);
  /* border-radius: 4px; */
  border: 1px solid rgba(var(--primary-blue), 1);
  cursor: pointer;
  box-sizing: border-box;
}

/* Small button */
.btn.btn-sm,
button.btn-sm {
  height: 17px;
  line-height: 17px;
  border-radius: 3px;
  font-size: 13px;
}

/* Large button */
.btn.btn-lg,
button.btn-lg {
  height: 48px;
  line-height: 48px;
  border-radius: 6px;
  font-size: 24px;
}

button.link {
  background: none;
  border: none;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-decoration: none;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
}

/* Button hover */
.btn:hover,
button:hover {
  filter: brightness(var(--button-hover-brightness));
}

.no-hover {
  pointer-events: none;
  background-color: rgba(var(--primary-white), 1);
}

.btn + .btn-content {
  width: auto;
  display: none;
  padding: 10px;
  padding-top: 25px;
  background-color: rgba(6, 164, 198, 0.1);
  border: 1px solid rgba(var(--primary-blue), 1);
  border-top: 0px;
  margin-top: -12px;
  min-width: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.btn + .btn-content.show {
  display: block;
}

/* Primary style */
.btn-primary {
  color: rgba(var(--primary-white), 1);
  background-color: rgba(var(--secondary-red), 1);
  border: 1px solid rgba(var(--secondary-red), 1);
}

/* Secondary style */
.btn-secondary {
  color: rgba(var(--primary-white), 1);
  background-color: rgba(var(--primary-blue), 1);
  border: 1px solid rgba(var(--primary-blue), 1);
}

.btn-tertiary {
  color: rgba(var(--secondary-purple), 1);
  border: 1px solid rgba(var(--secondary-purple), 1);
}

.btn-quartiary {
  color: rgba(var(--primary-white), 1);
  background-color: rgba(var(--tertiary-green), 1);
  border: 1px solid rgba(var(--tertiary-green), 1);
}

.btn-rounded {
  border-radius: 5px;
}

.btn-loading span {
  visibility: hidden;
  opacity: 0;
}

.btn-loading::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;

  border: 4px solid transparent;
  border-top-color: var(--primary-white);
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
