html,
body,
#root,
.App {
  height: 100%;
  background-color: rgba(var(--primary-white), 1);
}

.app-container {
  display: grid;
  grid-template-columns: 200px 1fr;
}

body {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 400px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(rgba(var(--primary-gray), 1), 1);
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(var(--primary-blue), 0.8);
  border-radius: 15px;
  border: none;
}

.container {
  height: auto;
}
.error-container {
  background-color: rgba(var(--secondary-red), 0.4);
  padding: 5px;
  border-radius: 8px;
  text-align: center;
  margin-top: 20px;
}
.auto-row-container {
  width: 100%;
  display: grid;
  grid-auto-flow: row;
}

.auto-col-container {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
}

.content {
  /* height: calc(100% - 68px); */
  margin: 0px;
}

.btn-container-inline {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.icon-container {
  border-radius: 5px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
}

.icon-container.rounded {
  border-radius: 50%;
}

.section {
  padding: 10px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.section:nth-child(odd) {
  background-color: rgba(27, 90, 109, 0.2);
}

.section:nth-child(even) {
  background-color: white;
}

.section .section {
  background-color: inherit;
  /* padding: 10px; */
}

ul.scrollable {
  flex-wrap: nowrap;
  margin: 2px 0px;
  overflow-y: scroll;
}

ul.scrollable li {
  background-color: rgba(27, 90, 109, 0.1);
}
ul.scrollable li:hover {
  background-color: rgba(27, 90, 109, 0.3);
}

.grid-container.halves-table {
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
}

.grid-container.thirds-table {
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
}

.grid-container.halves-2-1 {
  grid-template-columns: 2fr 1fr;
  text-align: left;
}

.grid-container.halves-1-3 {
  grid-template-columns: 1fr 3fr;
  text-align: left;
}

.grid-container.halves-3-1 {
  display: grid;
  grid-template-columns: 75% 25%;
  text-align: left;
}

.grid-container.halves-1-11 {
  grid-template-columns: 1fr 11fr;
  column-gap: 0px;

  text-align: left;
}

.grid-container.halves-15-1 {
  grid-template-columns: 15fr 1fr;
  text-align: left;
}

.grid-container.thirds-2-1-1 {
  grid-template-columns: 3fr 1fr 1fr;
  max-width: none;
  column-gap: 20px;
}

.grid-container.thirds-2-2-1 {
  grid-template-columns: 2fr 2fr 1fr;
}

.grid-container.thirds-2-3-1 {
  grid-template-columns: 2fr 8fr 1fr;
  text-align: left;
}
.grid-container.thirds-1-14-14 {
  grid-template-columns: 1fr 14fr 14fr;
}

.grid-container.thirds-14-14-1 {
  grid-template-columns: 40% 40% 20%;
}

.grid-container.thirds-3-3-6 {
  grid-template-columns: 3fr 3fr 6fr;
}

.grid-container.quarters-1 {
  grid-template-columns: 5fr 5fr 6fr 1fr;
}

.grid-container.quarters-2 {
  grid-template-columns: 4fr 6fr 1fr 4fr;
}

.grid-container.quarters-3 {
  grid-template-columns: 1fr 5fr 1fr 5fr;
}

.grid-container.fifths-1 {
  grid-template-columns: 4fr 5fr 5fr 1fr 4fr;
}

.grid-container.fifths-2 {
  grid-template-columns: 5fr 5fr 5fr 5fr 1fr;
}

.list-input-container {
  background-color: white;
  border: 2px solid #ddd;
  border-radius: 4px;
  padding: 10px 20px;
  text-align: left;
}

.grid-container.criteria-0 {
  margin: 0px;
  padding: 0px;
  grid-template-columns: 4fr 1fr 4fr;
}

.grid-container.criteria-1 {
  margin: 0px;
  padding: 0px;
  grid-template-columns: 1fr;
}

.grid-container.criteria-2 {
  margin: 0px;
  padding: 0px;
  grid-template-columns: 1fr;
}

.grid-container.criteria-3 {
  margin: 0px;
  padding: 0px;
  grid-template-columns: 1fr;
}

.grid-container.criteria-4 {
  margin: 0px;
  padding: 0px;
  grid-template-columns: 7fr 1fr;
}

.grid-container.criteria-5 {
  margin: 0px;
  padding: 0px;
  grid-template-columns: 7fr 1fr;
}

.auto-grid {
  --min-column-size: 120px;
  display: grid;
  gap: 4px;
  grid-template-columns: repeat(
    auto-fit,
    minmax(min(var(--min-column-size), 100%), 1fr)
  );
}

.flex-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.flex-grid > * {
  flex: 1;
}

.campaign-body {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin: 0px;
  padding: 0px;
  padding-top: 5px;
}

.campaign-body > :first-child {
  flex-basis: 500px;
  flex-grow: 9999;
}
.campaign-body > :last-child {
  flex-basis: 300px;
  flex-grow: 1;
}

.campaign-header {
  display: grid;
  grid-template-columns: 50px 1fr 200px;
  column-gap: 5px;
  margin-bottom: 10px;
}

.id-container {
  display: flex;
  flex: 1;
  justify-content: flex-start;
}

@media (max-width: 800px) {
  .campaign-body {
    flex-wrap: wrap;
  }
  .campaign-body > :first-child {
    order: 2;
  }
  .campaign-body > :last-child {
    order: 1;
  }
}
