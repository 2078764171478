table {
  border-collapse: collapse;
  table-layout: auto;
  border-spacing: 0;
  width: 100%;
  font-size: var(--fs-sm);
  margin-bottom: 10px;
}

th {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  color: rgba(39, 46, 53, 0.5);
  /* background-color: rgba(var(--primary-blue),1);
    border: 1px solid rgba(var(--primary-blue),1); */
  /* padding: 5px 5px 0px 5px; */
}

th:first-child {
  text-align: left;
}

td {
  text-align: center;
  font-size: var(--fs-sm);
  font-weight: 600;
  padding: 4px 2px;
  border: 1px solid rgba(var(--primary-gray), 1);
  max-width: 500px;
  overflow-wrap: break-word;
}

td.left {
  text-align: left;
}

td:first-child {
  text-align: left;
}

td.name {
  font-weight: bold;
  width: 40%;
  white-space: nowrap;
}

tbody tr {
  background-color: rgba(var(--primary-white), 1);
}

tbody tr.indicator-1 {
  background-color: rgba(var(--primary-blue), 1);
  /* box-shadow: inset 8px 0px rgba(var(--primary-blue),1),
      inset -8px 0px rgba(var(--primary-blue),1); */
}
tbody tr.indicator-1:nth-child(odd).react-draggable-dragging {
  border: solid 2px rgba(var(--primary-blue), 1);
  border-left: none;
  border-right: none;
}

tbody tr.indicator-1:nth-child(even).react-draggable-dragging {
  border: solid 2px rgba(var(--primary-white), 1);
  border-left: none;
  border-right: none;
  color: white;
  background-color: rgba(27, 90, 109, 1);
}

tbody tr.indicator-1:nth-child(even) {
  background-color: rgba(var(--primary-blue), 0.2);
}

tbody tr.indicator-1:nth-child(odd) {
  /* background-color: rgba(27, 90, 109, 0.3); */
  background-color: white;
}

tbody tr.indicator-2 {
  background-color: rgba(var(--primary-light-blue), 1);
}

tbody tr.indicator-2:nth-child(even) {
  background-color: rgba(6, 164, 198, 0.2);
}

tbody tr.indicator-2:nth-child(odd) {
  background-color: rgba(6, 164, 198, 0.3);
}

tbody tr.indicator-3 {
  background-color: rgba(var(--secondary-red), 1);
}

tbody tr.indicator-3:nth-child(even) {
  background-color: rgba(var(--secondary-red), 0.2);
}

tbody tr.indicator-3:nth-child(odd) {
  background-color: rgba(var(--secondary-red), 0.3);
}

tbody tr.indicator-4 {
  background-color: rgba(var(--tertiary-green), 1);
}

tbody tr.indicator-4:nth-child(even) {
  background-color: rgba(57, 158, 90, 0.2);
}

tbody tr.indicator-4:nth-child(odd) {
  background-color: rgba(57, 158, 90, 0.3);
}

.failure {
  background-color: rgba(var(--secondary-red), 0.5);
}
.success {
  background-color: rgba(var(--tertiary-green), 0.5);
}

tbody tr.text-green {
  color: green;
}

tbody tr.text-lblue {
  color: cyan;
}

tbody tr.text-blue {
  color: blue;
}

tbody tr.text-orange {
  color: orange;
}

tbody tr.text-purple {
  color: purple;
}

tbody tr.text-pink {
  color: hotpink;
}

tbody tr.disabled {
  filter: brightness(0.7) opacity(0.5);
}

tbody tr.disabled td:first-child {
  box-shadow: inset 8px 0px black;
}

/* Rounded table styles */

table.rounded {
  border: none;
}

table.rounded th {
  border: none;
}

table.rounded tr:first-child th:first-child {
  border-top-left-radius: 10px;
}

/* Tooltip header case: */
table.rounded tr:first-child .tooltip-container th {
  padding: 0px;
}

table.rounded tr:first-child th:last-child {
  border-top-right-radius: 10px;
}

table.rounded td {
  border: none;
}

table.rounded tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

table.rounded tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

table.rounded tr:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

table:not(.rounded) tr:last-child td:first-child {
  border-bottom-left-radius: 0px;
}

table:not(.rounded) tr:last-child td:last-child {
  border-bottom-right-radius: 0px;
}

table:not(.rounded) tr:last-child {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
