body {
  margin: 0;
  font-family: "poppins", sans-serif;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  padding: 0;
  margin: 0;
}

h1 {
  font-size: var(--fs-64);
  line-height: 1.2;
  letter-spacing: -0.1rem;
}
h2 {
  font-size: var(--fs-36);
  line-height: 1.25;
  letter-spacing: -0.1rem;
}
h3 {
  font-size: var(--fs-28);
  line-height: 1.3;
  letter-spacing: -0.1rem;
}
h4 {
  font-size: var(--fs-24);
  line-height: 1.35;
  letter-spacing: -0.08rem;
}
h5 {
  font-size: var(--fs-24);
  line-height: 1.5;
  letter-spacing: -0.05rem;
  font-weight: normal;
  color: rgba(var(--primary-blue), 1);
}
h6 {
  font-size: var(--fs-18);
  line-height: 1.6;
  letter-spacing: 0;
  font-weight: normal;
}

/* Custom sizing for monitors (same as of now)*/
@media (min-width: 600px) {
  h1 {
    font-size: var(--fs-64);
  }
  h2 {
    font-size: var(--fs-36);
  }
  h3 {
    font-size: var(--fs-28);
  }
  h4 {
    font-size: var(--fs-24);
  }
  h5 {
    font-size: var(--fs-24);
    font-weight: normal;
  }
}
