.page-container {
  list-style: none;
  display: flex;
  margin-bottom: 0px;
}

.page-container li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  border-top: 1px solid rgba(var(--primary-blue), 1);
  border-bottom: 1px solid rgba(var(--primary-blue), 1);
  background-color: white;
  cursor: pointer;
}

.page-container li:first-child {
  padding: 2px;
  border: 1px solid rgba(var(--primary-blue), 1);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: default;
}

.page-container li:last-child {
  padding: 2px;
  border: 1px solid rgba(var(--primary-blue), 1);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: default;
}

.page-container li.active {
  background-color: rgba(var(--primary-blue), 1);
  color: white;
}

.page-container li.button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.page-container li.button:hover {
  background-color: white;
  color: black;
}

.page-container li.button:focus {
  outline: none;
}
