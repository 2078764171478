input,
select {
  font-size: var(--fs-sm);
  margin: 0;
  padding: 0;
  width: 100%;
}

.multi-select {
  max-width: 400px;
  width: 100%;
  font-size: 13px;
  margin: auto;
}

.multi-select-2 {
  /* max-width: 400px; */
  width: 100%;
  font-size: 13px;
  /* margin: auto; */
}

.multi-select .dropdown-content {
  z-index: 300 !important;
}

.searchable-select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.searchable-select {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  display: none;
  z-index: 100000000000000000000000000000;
  overflow-y: auto;
}

input[type="checkbox"] {
  vertical-align: middle;
  width: 24px;
}

input:invalid,
select:invalid {
  border: 1px solid rgba(var(--secondary-red), 1);
  outline: 0;
}

input ~ .error,
select ~ .error,
textarea ~ .error,
p.error {
  font-size: 12px;
  margin: 0px;
  color: rgba(var(--secondary-red), 1);
  display: block;
}

input:invalid ~ .error {
  display: block;
}

input ~ .valid,
select ~ .valid,
textarea ~ .valid {
  font-size: 12px;
  margin: 0px;
  color: rgba(var(--primary-light-blue), 1);
  display: block;
}

label {
  text-align: left;
  font-size: var(--fs-sm);
  font-weight: 600;
}
