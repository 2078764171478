.alert-container {
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 100000000;
}

.alert {
  padding: 10px;
  border-radius: 5px;
  color: rgba(var(--primary-gray), 1);
  opacity: 0.8;
  transition: opacity 0.3s ease;
  z-index: 100000000;
}

.alert-success {
  background-color: rgba(var(--tertiary-green), 1);
  border: 1px solid rgba(var(--tertiary-green), 1);
}
.alert-error {
  background-color: rgba(var(--secondary-red), 1);
  border: 1px solid rgba(var(--secondary-red), 1);
}
.alert-warning {
  background-color: rgba(var(--tertiary-yellow), 1);
  border: 1px solid rgba(var(--tertiary-yellow), 1);
}
.alert-info {
  background-color: rgba(var(--secondary-blue), 1);
  border: 1px solid rgba(var(--secondary-blue), 1);
}
