.loader {
  width: 100px;
  height: 100px;
  margin: auto;
  margin-top: 40px;
  border: 15px solid #e9ecef;
  border-radius: 50%;
  border-top: 15px solid rgba(var(--primary-light-blue), 1);
  animation: spinner 2s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
