.login-container {
  width: 100%;
  height: 200;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.login {
  width: 200px;
  margin: 30px;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(var(--primary-blue), 1);
  border-radius: 20px;
}
