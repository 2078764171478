html {
  --primary-white: 255, 255, 255; /* #FFFFFF */
  --primary-blue: 0, 85, 111;
  /* 0, 85, 111; #00556F */
  /* 20, 72, 129 */
  /* 20, 97, 104 */
  /* 54, 33, 62 */
  --primary-light-blue: 6, 164, 198; /* #06A4C6 */
  --primary-black: 39, 46, 53; /* #272E35 */
  --primary-gray: 233, 236, 239; /* #E9ECEF */

  --secondary-blue: 0, 61, 104;
  --secondary-red: 205, 49, 97; /* #CD3161 */
  --secondary-purple: 54, 33, 62;

  --tertiary-yellow: 255, 200, 87;
  --tertiary-orange: 250, 131, 52;
  --tertiary-green: 57, 158, 90; /* #399E5A */

  --button-hover-brightness: 0.9;

  --fs-sm: 15px;
  --fs-18: 18px;
  --fs-24: 24px;
  --fs-28: 28px;
  --fs-36: 36px;
  --fs-64: 64px;
}
