/********** Header Styles **********/

.adquire-header {
  background-color: rgba(var(--primary-blue), 1);
}

.header-list {
  max-width: none;
  padding: 0px;
}

/* Logo styling */
.header-logo {
  height: 100%;
  max-height: 60px;
  padding: 0px;
}

/* li class for header navbar */
.header-item {
  margin: 5px 5px 5px 20px;
  align-items: left;
  cursor: pointer;
  color: rgba(var(--primary-white), 1);
}

.header-item:hover {
  color: rgba(var(--primary-light-blue), 1);
}

.header-item:first-child {
  margin: 0px;
  background-color: rgba(var(--primary-blue), 1);
  border-bottom-right-radius: 5px;
}

.header-item ul li {
  text-align: left;
  margin: 0px;
  padding: 0px;
}

/* link class for header navba */
.header-item ul li a {
  color: rgba(var(--primary-white), 1);
  text-decoration: none;
  border: 0px;
  text-overflow: wrap;
}

/* search-bar class for header navbar */
.header-item div .search-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 75%;
  margin: 0px;
  padding: 0px;
}

/* link highlight on hover */
.header-item ul li a:hover {
  /* background-color: rgba(var(--primary-light-blue),1); */
  /* border: 1px solid rgba(var(--primary-blue),1); */
  cursor: pointer;
  color: rgba(var(--primary-light-blue), 1);
}

/* Navigation dropdown div */
.header-item .nav-content {
  display: none;
  position: absolute;
  background-color: rgba(var(--primary-white), 1);
  min-width: 160px;
  height: auto;
  overflow: hidden;
  border: 1px solid rgba(var(--primary-blue), 1);
  border-top: 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100000;
}

/* dropdown div */
.header-item li a {
  color: rgba(var(--primary-light-blue), 1);
  font-size: var(--fs-sm);
  margin: 0px;
  text-decoration: none;
  text-align: left;
}

/* Header icon styling */
.material-icons .header-icon,
.material-icons-outlined .header-icon {
  max-height: 24px;
  max-width: 24px;
  padding: 0;
  margin-right: 5px;
}

/* link icon color */
.header-item span.material-icons,
.header-item span.material-icons-outlined {
  color: rgba(var(--primary-light-blue), 1);
}

/* icon highlight on link hover */
/* .header-item:hover span.material-icons,
.header-item:hover span.material-icons-outlined {
  color: rgba(var(--primary-white), 1);
} */
