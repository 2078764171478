.campaigns-head {
  max-width: none;
  text-align: left;
  margin: 0px;
  padding-top: 0px;
}

.campaigns-toolbar {
  max-width: none;
  padding: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filter-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

/* Advanced Filter style */

.advanced-filter-display {
  border: 1px solid #aaa;
  border-radius: 8px;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
  height: 500px;
}

.advanced-filter-tools {
  width: 100%;
  padding: 20px;
  height: 500px;
}
