.tooltip-container {
  /* width: fit-content; */
  position: relative;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
}

.tooltip-tip {
  position: absolute;
  border-radius: 4px;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  color: rgba(var(--primary-white), 1);
  background: rgba(var(--primary-black), 1);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 1000000000;
  white-space: nowrap;
}

.tooltip-tip::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: 6px;
  margin-left: calc(6px * -1);
}

.tooltip-tip.top {
  top: calc(30px * -1);
}

.tooltip-tip.top::before {
  top: 100%;
  border-top-color: rgba(var(--primary-black), 1);
}

.tooltip-tip.right {
  left: calc(100% + 30px);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

.tooltip-tip.right::before {
  left: calc(6px * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: rgba(var(--primary-black), 1);
}

.tooltip-tip.bottom {
  bottom: calc(30px * -1);
}

.tooltip-tip.bottom::before {
  bottom: 100%;
  border-bottom-color: rgba(var(--primary-black), 1);
}

.tooltip-tip.left {
  left: auto;
  right: calc(100% + 30px);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

.tooltip-tip.left::before {
  left: auto;
  right: calc(6px * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: rgba(var(--primary-black), 1);
}

.tooltip-container th {
  border-radius: 0px;
  padding: 20px 0px;
}
