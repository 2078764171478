.tabs-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
  width: 75%;
}
.tabs-container > * {
  flex: 0 1;
}

.tabs {
  display: table-cell;
  vertical-align: middle;
  margin-right: 0px;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  color: rgba(var(--primary-light-blue), 1);
  border: 1px solid #ccc;
  border-bottom: 0px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 5px;
  width: 75%;
  text-wrap: nowrap;
}

.tabs:hover {
  border-color: rgba(var(--primary-light-blue), 1);
}

.tabs.active {
  background-color: rgba(var(--primary-blue), 1);
  border: 2px solid rgba(var(--primary-blue), 1);
  color: #fff;
  /* border-bottom: 2px solid #ccc; */
  border-bottom: none;
}

.tabs-hr {
  margin: 0;
  padding: 0;
  border: 1px solid rgba(var(--primary-blue), 1);
  width: 75%;
}

@media (max-width: 1000px) {
  .tabs-container {
    width: 100%;
  }

  .tabs-hr {
    width: 100%;
  }
}
